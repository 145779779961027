import axios, { AxiosResponse } from 'axios';
import { ApiUrl } from '../constants';

const ErrorCodeParticipantNotHost: number = 1000;
const ErrorCodeHasReachedEndAtThreshold: number = 2;
const ErrorCodeHasNotReachedStartAtThreshold: number = 1;

export const roomService = {
  addNote: (room: string, noteText: string, signedParticipantId: string, name?: string | null): Promise<string> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ApiUrl}twilio_video/notes/create_or_update`, {
          room_id: room,
          note_text: noteText,
          participant_name: name,
          participant_id: signedParticipantId,
        })
        .then(
          (response: AxiosResponse) => {
            resolve();
          },
          (error: any) => {
            if (error.response && error.response.data && error.response.data.error_code) {
              let errorCode = error.response.data.error_code;

              switch (errorCode) {
                case ErrorCodeParticipantNotHost:
                  reject('You are not the Host!');
                  break;
                default:
                  reject('Unknown Error');
              }
            } else {
              reject(error);
            }
          }
        );
    });
  },

  connect:(name: string, room: string, participantId: string, passcode?: string): Promise<{name:string, participantId:string, token:string, locked:boolean, canLockRoom:boolean, channelName:string, signedParticipantId:string}> => {
    return new Promise((resolve, reject) => {
      let data:{} = {
        room_id:room,
        passcode:passcode,
        participant_name:name,
      };
      if(participantId.length > 0){
        data = {
          room_id:room,
          passcode:passcode,
          participant_name:name,
          participant_id:participantId
        };
      }
      axios
        .post(`${ApiUrl}twilio_video/rooms/connect`, data)
        .then(
          (response: AxiosResponse) => {
            const token = response?.data?.token;
            const locked = response?.data?.room?.locked;
            const channelName = response?.data?.room?.twilio_channel_sid;
            const canLockRoom = response?.data?.participant.is_host;
            const participantId = response?.data?.participant.id;
            const participantName = response?.data?.participant.name;
            const signedParticipantId = response?.data?.signed_participant_id;

            if (token) {
              resolve({
                name: participantName,
                participantId: participantId,
                token: token,
                locked: locked,
                canLockRoom: canLockRoom,
                channelName: channelName,
                signedParticipantId: signedParticipantId,
              });
            } else {
              reject();
            }
          },
          (error: any) => {
            if (error.response && error.response.data && error.response.data.error_code) {
              let errorCode = error.response.data.error_code;

              switch (errorCode) {
                case ErrorCodeHasNotReachedStartAtThreshold:
                  reject('It’s not time for the meeting yet.');
                  break;
                case ErrorCodeHasReachedEndAtThreshold:
                  reject('The appointment is over.');
                  break;
                default:
                  reject('Unknown Error');
              }
            } else {
              reject(error);
            }
          }
        );
    });
  },

  lockRoom: (room: string, isLocked: boolean, signedParticipantId: string, name?: string | null): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${ApiUrl}twilio_video/rooms`, {
          room_id: room,
          is_locked: isLocked,
          participant_name: name,
          participant_id: signedParticipantId,
        })
        .then(
          (response: AxiosResponse) => {
            const locked = response?.data?.is_locked;
            resolve(locked);
          },
          (error: any) => {
            if (error.response && error.response.data && error.response.data.error_code) {
              let errorCode = error.response.data.error_code;

              switch (errorCode) {
                case ErrorCodeParticipantNotHost:
                  reject('You are not the Host!');
                  break;
                default:
                  reject('Unknown Error');
              }
            } else {
              reject(error);
            }
          }
        );
    });
  },

  addMessage: (roomID: string, message: string, signedParticipantId: string, name?: string | null): Promise<string> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ApiUrl}twilio_video/messages`, {
          room_id: roomID,
          message_text: message,
          participant_name: name,
          participant_id: signedParticipantId,
        })
        .then(
          (response: AxiosResponse) => {
            resolve();
          },
          (error: any) => {
            if (error.response && error.response.data && error.response.data.error_code) {
              let errorCode = error.response.data.error_code;

              switch (errorCode) {
                case ErrorCodeParticipantNotHost:
                  reject('You are not the Host!');
                  break;
                default:
                  reject('Unknown Error');
              }
            } else {
              reject(error);
            }
          }
        );
    });
  },
};
