import React, { useEffect } from 'react';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import Participant from '../Participant/Participant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import { RemoteParticipant } from 'twilio-video';
import { CircularProgress } from '@material-ui/core';

const Container = styled('aside')(({ theme }) => ({
  padding: '0',
  overflowY: 'auto',
  position: 'absolute',
  zIndex: 69,
  margin: '40px',
  minHeight: `${(theme.sidebarWidth * 9) / 16 + 20}px`,
  maxHeight: '100%',
  height: 'auto',

  [theme.breakpoints.down('xs')]: {
    overflowY: 'initial',
    overflowX: 'scroll',
    padding: '0px',
    display: 'flex',
    margin: '10px',
    width: 'calc(100% - 20px)',
    minHeight: `auto`,
    height: `((100vw / 2.5) * 9) / 16))`,
  },
}));

const useStyles = makeStyles(theme =>
  createStyles({
    participantLoadingCircle: {
      display: 'flex',
      height: `${(theme.sidebarWidth * 9) / 16}px`,
      width: `${theme.sidebarWidth}px`,
      borderRadius: '5px',
      backgroundColor: 'white',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColour: 'white',
      fontSize: '22px',
      color: 'black',

      [theme.breakpoints.down('xs')]: {
        fontSize: '17px',
        width: 'calc((100vw / 1.75) - 20px)',
        height: 'calc(((100vw / 1.75) *  9) / 16)',
      },
    },
  })
);

const ScrollContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
  },
}));

export default function ParticipantStrip() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const {room: { localParticipant }} = useVideoContext();
  const participants = useParticipants();
  const [participantsArray, setParticipantsArray] = React.useState(Array.from(room.participants.values()));
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  // Arya
  // const primaryColour = 'rgb(33,147,134)';

  // SCC
  const primaryColour = '#16405b';

  if(!selectedParticipant && participants && participants.length > 0){
    setSelectedParticipant(participants[0]);
  }

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) => {
      setTimeout(function() {
        setSelectedParticipant(participant);
        setParticipantsArray(Array.from(room.participants.values()));
      }, 1000);
    };
    const participantDisconnected = () => setSelectedParticipant(localParticipant);
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  return (
    <Container>
      <ScrollContainer>
        {selectedParticipant !== localParticipant && participantsArray && participantsArray.length > 0 ? (
              <Participant isLocal={true}
                           participant={localParticipant}
                           isSelected={selectedParticipant === localParticipant}
                           onClick={() => setSelectedParticipant(localParticipant)}
              />
          )
          :
          null
        }

        {participantsArray && participantsArray.length > 0 ?
          <>
            {participants.map(participant =>
              <div key={participant.sid}
                   style={{display:((selectedParticipant && participant.sid !== selectedParticipant.sid) ? 'block' : 'none')}}>
                <Participant isLocal={false}
                             onClick={() => setSelectedParticipant(participant)}
                             isSelected={selectedParticipant === participant}
                             participant={participant}
                />
              </div>
            )
            }
          </>
          :
          <div className={'participant-video-small '}
               onLoad={() => setSelectedParticipant(localParticipant)}>
            <div className={classes.participantLoadingCircle}>
              <div style={{ marginBottom: '20px', fontWeight: 'bold', fontSize: '17px' }}>
                Waiting...
              </div>

              <CircularProgress style={{ color: primaryColour }}/>
            </div>
          </div>
        }
      </ScrollContainer>
    </Container>
  );
}
