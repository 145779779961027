import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import { LocalVideoTrack, Participant, RemoteVideoTrack } from 'twilio-video';

import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import VideocamOff from '@material-ui/icons/VideocamOff';
import {participantService} from "../../services/participant.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      gridArea: 'participantList',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      height: '100%',
      padding: '20px',
      width: '100%',
      '& video': {
        borderRadius: '5px',
        objectFit: 'cover',
        height: '100%',
        objectPosition: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0px',

        '& video': {
          borderRadius: '0px',
        },
      },
    },
    isVideoSwitchedOff: {
      background: 'rgba(28,32,36,1)',
      position: 'absolute',
      height: 'calc(100% - 40px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'calc(100% - 40px)',
      borderRadius: '5px',
      top: '20px',
      left: '20px',

      '& video': {
        background: 'rgba(28,32,36,1)',
      },
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.7)',
      padding: '0.1em 0.3em',
      margin: '1em',
      fontSize: '1.2em',
      display: 'inline-flex',
      '& svg': {
        marginLeft: '0.3em',
      },
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 1,
    },
    mainParticipantName: {
      position: 'absolute',
      bottom: '25px',
      left: '25px',
      display: 'flex',
      color: 'white',
      zIndex: 67,
      padding: '3px 5px',
      backgroundColor: 'rgba(0,0,0,0.6)',
      borderRadius: '5px',
      fontWeight: 600,
    },
  })
);

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  isLocal:boolean;
}

export default function MainParticipantInfo({ participant, children, isLocal}: MainParticipantInfoProps) {
  const classes = useStyles();

  const publications = usePublications(participant);
  const videoPublication = publications.find(p => (p.kind === 'video'));
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));
  const isVideoEnabled = Boolean(videoPublication);

  const [primaryColour, setPrimaryColour] = React.useState('');
  const [backgroundColour, setBackgroundColour] = React.useState('');
  const [fontColourLight, setFontColourLight] = React.useState('');
  const [fontColourDark, setFontColourDark] = React.useState('');

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const [participantName, setParticipantName] = useState('');

  useEffect(() => {

    // Arya
    // setPrimaryColour('rgb(33,147,134)');
    //setBackgroundColour('#ffffff');
    //setFontColourDark('#black');
    //setFontColourLight('#eeeeee');

    // SCC
    setPrimaryColour('#16405b');
    setBackgroundColour('#eeeeee');
    setFontColourDark('#4a4a4a');
    setFontColourLight('#eeeeee');
  });

  useEffect(() => {
    const ac = new AbortController();
    participantService.getParticipantName(participant.identity).then(name => {
      if(isLocal){
        setParticipantName(name + ' (you)');
      }
      else{
        setParticipantName(name);
      }
    })
      .catch(reason => {console.log('MainParticipantInfo (useEffect): Could not fetch Participants Name with error: ' + reason)});
    return () => ac.abort(); // Abort both fetches on unmount
  }, [participant, isLocal]);
  
  return (
    <div className={clsx(classes.container, {[classes.isVideoSwitchedOff]:isVideoSwitchedOff})}
         data-cy-main-participant>
      <div className={clsx(classes.infoContainer, {[classes.isVideoSwitchedOff]:(!isVideoEnabled || isVideoSwitchedOff)})}>
        {(!isVideoEnabled || isVideoSwitchedOff) ?
          <VideocamOff style={{color:fontColourLight, fontSize:'50px'}}/>
          :
          null
        }
      </div>
      
      {children}
      
      <div className={classes.mainParticipantName}>
        {participantName}
      </div>
    </div>
  );
}
