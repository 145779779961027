import axios, { AxiosResponse } from 'axios';
import { ApiUrl } from '../constants';

interface ParticipantNameLookup {
  [participantId: string]: string;
}

// TODO: Renable if running on Node Server. Also change in the Menu Bar.
const testingLocally = false;
const participantNameLookup: ParticipantNameLookup = {};

export const participantService = {
  getParticipantName:(participantId: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      let returnValue:Promise<any>;

      if(testingLocally){
        participantNameLookup[participantId] = participantId;
      }
      if(participantNameLookup[participantId] !== undefined){
        returnValue = Promise.resolve(participantNameLookup[participantId]);
        returnValue.then(() =>{resolve(participantNameLookup[participantId])});
      }
      else{
        returnValue = axios.get(`${ApiUrl}twilio_video/participants/${participantId}`).then(
          (response: AxiosResponse) => {
            const name = response?.data?.name;

            if(name){
              participantNameLookup[participantId] = name;
              resolve(name);
            }
            else{
              reject();
            }
          },
          (error: any) => {
            reject(error);
          }
        );
      }
      return returnValue;
    });
  },
};
