import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant } from 'twilio-video';
import {createStyles, makeStyles, styled, Theme, createMuiTheme} from '@material-ui/core/styles';
import {participantService} from "../../services/participant.service";

interface ParticipantProps {
  participant: IParticipant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  onClick: () => void;
  isSelected: boolean;
  isLocal:boolean;
}
const useStyles = makeStyles(theme =>
  createStyles({
    participantName: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      display: 'flex',
      color: 'white',
      zIndex: 67,
      padding: '3px 5px',
      backgroundColor: 'rgba(0,0,0,0.6)',
      borderRadius: '5px',
      fontWeight: 600,
      margin: '5px',
    },
  })
);

export default function Participant({
  participant,
  disableAudio,
  enableScreenShare,
  onClick,
  isSelected,
  isLocal,
}: ParticipantProps) {
  const classes = useStyles();
  const [participantName, setParticipantName] = useState('');

  useEffect(() => {
    const ac = new AbortController();
    participantService.getParticipantName(participant.identity).then(name => {
        if(isLocal){
          setParticipantName(name + ' (you)');
        }
        else{
          setParticipantName(name);
        }
    })
      .catch(reason => {console.log('Participant (useEffect): Could not fetch Participants Name with error: ' + reason)});
    return () => ac.abort(); // Abort both fetches on unmount
  }, [participant, isLocal]);

  return (
    <ParticipantInfo participant={participant} onClick={onClick} isSelected={isSelected}>
      <div className={classes.participantName}>{participantName}</div>
      <ParticipantTracks participant={participant} disableAudio={disableAudio} enableScreenShare={enableScreenShare} />
    </ParticipantInfo>
  );
}
