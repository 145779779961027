import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import NetworkQualityLevel from '../NewtorkQualityLevel/NetworkQualityLevel';
import ParticipantConnectionIndicator from './ParticipantConnectionIndicator/ParticipantConnectionIndicator';
import PinIcon from './PinIcon/PinIcon';
import ScreenShare from '@material-ui/icons/ScreenShare';
import VideocamOff from '@material-ui/icons/VideocamOff';

import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import usePublications from '../../hooks/usePublications/usePublications';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useTrack from '../../hooks/useTrack/useTrack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { participantService } from '../../services/participant.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      borderRadius: '5px',
      height: `${(theme.sidebarWidth * 9) / 16}px`,
      width: `${theme.sidebarWidth}px`,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
      marginRight: '0px',
      marginBottom: '10px',
      '& video': {
        filter: 'none',
      },
      '& svg': {
        stroke: 'black',
        strokeWidth: '0.8px',
      },
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        width: 'calc((100vw / 1.75) - 20px)',
        marginRight: '10px',
        fontSize: '10px',
        marginBottom: '0px',
      },
    },
    isVideoSwitchedOff: {
      '& video': {
        filter: 'blur(4px) grayscale(1) brightness(0.5)',
        background: 'rgb(75,75,75)',
      },
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: '100%',
      width: '100%',
    },
    hideVideo: {
      background: 'rgb(75,75,75)',
      height: '100%',
      width: '100%',
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    identity: {
      // TODO: Arya Background
      // background: 'rgb(33,147,134)!important' as any,
      // TODO: SCC Background
      background: '#16405B!important' as any,
      margin: 0,
      display: 'flex',
      fontWeight: 'normal',
    },
    infoRow: {
      // TODO: Arya Background
      // background: 'rgb(33,147,134)!important' as any,
      // TODO: SCC Background
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.4em',
      color: 'white',
      alignItems: 'center',
      height: '35px',
      fontSize: '15px',
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
}

export default function ParticipantInfo({ participant, onClick, isSelected, children }: ParticipantInfoProps) {
  const [participantName, setParticipantName] = useState('');
  const publications = usePublications(participant);

  const [primaryColour, setPrimaryColour] = React.useState('');
  const [backgroundColour, setBackgroundColour] = React.useState('');
  const [fontColourLight, setFontColourLight] = React.useState('');
  const [fontColourDark, setFontColourDark] = React.useState('');

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => (p.kind === 'video'));

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;

  const classes = useStyles();
  //
  // participantService.getParticipantName(participant.identity).then(name => {
  //   setParticipantName(name);
  // });

  useEffect(() => {
    // Arya
    // setPrimaryColour('rgb(33,147,134)');
    //setBackgroundColour('#ffffff');
    //setFontColourDark('#black');
    //setFontColourLight('#eeeeee');
    //setLogo('../../assets/images/aryaLogo.png');

    // SCC
    setPrimaryColour('#16405b');
    setBackgroundColour('#eeeeee');
    setFontColourDark('#4a4a4a');
    setFontColourLight('#eeeeee');
    // setLogo("../../assets/images/aryaLogo.png");
  });

  return (
    <div className={clsx(classes.container, {[classes.isVideoSwitchedOff]:isVideoSwitchedOff})}
         onClick={onClick}
         data-cy-participant={participant.identity}>
      <div className={clsx(classes.infoContainer, {[classes.hideVideo]:(!isVideoEnabled || isVideoSwitchedOff)})}>
        <div className={classes.infoRow}>
          <NetworkQualityLevel qualityLevel={networkQualityLevel}/>
          
          <AudioLevelIndicator audioTrack={audioTrack}/>
          
          {(!isVideoEnabled || isVideoSwitchedOff) ?
            <VideocamOff style={{color:fontColourLight}}/>
            :
            null
          }
          
          {isScreenShareEnabled ?
            <ScreenShare style={{color:fontColourLight}}/>
            :
            null
          }
          
          {isSelected ?
            <PinIcon />
            :
            null
          }
        </div>
      </div>

      {children}
    </div>
  );
}
