import React, { useState, useRef, useCallback } from 'react';
import AboutDialog from '../AboutDialog/AboutDialog';
import IconButton from '@material-ui/core/IconButton';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsDialog from '../SettingsDialog/SettingsDialog';
import UserAvatar from '../UserAvatar/UserAvatar';

import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import VideocamOff from "@material-ui/core/SvgIcon/SvgIcon";

export default function Menu({className}:{className?:string;}){
  const { user, signOut } = useAppState();
  const { room, localTracks } = useVideoContext();

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleSignOut = useCallback(() => {
    room.disconnect?.();
    localTracks.forEach(track => track.stop());
    signOut?.();
  }, [room.disconnect, localTracks, signOut]);

  return (
    <div className={className}
         ref={anchorRef}>
      {/*<IconButton color="inherit" onClick={() => setSettingsOpen(true)}>*/}
      {/*  {user ? <UserAvatar user={user} /> : <MoreIcon style={{color:"white"}}/>}*/}
      {/*</IconButton>*/}
      {/*<MenuContainer open={menuOpen} onClose={() => setMenuOpen(state => !state)} anchorEl={anchorRef.current}>*/}
      {/*  {user?.displayName && <MenuItem disabled>{user.displayName}</MenuItem>}*/}
      {/*  <MenuItem onClick={() => setAboutOpen(true)}>About</MenuItem>*/}
      {/*  <MenuItem onClick={() => setSettingsOpen(true)}>Settings</MenuItem>*/}
      {/*  {user && <MenuItem onClick={handleSignOut}>Logout</MenuItem>}*/}
      {/*</MenuContainer>*/}
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      
      <SettingsIcon color='inherit'
                    onClick={() => setSettingsOpen(true)}
      />
      
      <SettingsDialog open={settingsOpen}
                      onClose={() => setSettingsOpen(false)}
      />
    </div>
  );
}
