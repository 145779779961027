import React, { useState } from 'react';
import { Button, Dialog, DialogActions, Tab, Tabs, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ConnectionOptions from '../ConnectionOptions/ConnectionOptions';
import { DeviceSelector } from '../DeviceSelector/DeviceSelector';
import Video from 'twilio-video';
import {version as appVersion} from '../../../../package.json';
import {useAppState} from '../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '525px',
      minHeight: '400px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 32px)'
      },
      '& .inputSelect': {
        width: 'calc(100% - 35px)'
      },
    },
    button: {
      float: 'right'
    },
    paper: {
      [theme.breakpoints.down('xs')]: {
        margin: '16px'
      },
    },
    aboutDetail: {
      margin: '24px'
    }
  })
);

export default function SettingsDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const classes = useStyles();
  const {roomType} = useAppState();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Devices" />
        <Tab label="Settings" />
        <Tab label="About" />
      </Tabs>
      <DeviceSelector className={classes.container} hidden={selectedTab !== 0} />
      <ConnectionOptions className={classes.container} hidden={selectedTab !== 1} />
  
      {selectedTab === 2 ?
        <div className={classes.container}>
          <div className={classes.aboutDetail}>
            <b>Browser supported:</b> {Video.isSupported ? 'Yes' : 'No'}
          </div>
          
          <div className={classes.aboutDetail}>
            <b>SDK Version:</b> {Video.version}
          </div>
          
          <div className={classes.aboutDetail}>
            <b>App Version:</b> {appVersion}
          </div>
          
          <div className={classes.aboutDetail}>
            <b>Deployed Tag:</b> {process.env.REACT_APP_GIT_TAG || 'N/A'}
          </div>
          
          <div className={classes.aboutDetail}>
            <b>Deployed Commit Hash:</b> {process.env.REACT_APP_GIT_COMMIT || 'N/A'}
          </div>
          
          {roomType ?
            <div className={classes.aboutDetail}>
              <b>Room Type:</b> {roomType}
            </div>
            :
            null
          }
        </div>
        :
        null
      }
      
      <DialogActions>
        <Button className={classes.button} onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
