import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, SnackbarContent } from '@material-ui/core';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import LocalAudioLevelIndicator from './DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import Snackbar from '@material-ui/core/Snackbar';
import Drawer from '@material-ui/core/Drawer';
import Modal from '@material-ui/core/Modal';
import { roomService } from '../../services/room.service';

import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ChatIcon from '@material-ui/icons/Chat';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';

import { Channel } from 'twilio-chat/lib/channel';
import Client from 'twilio-chat';
import { Message } from 'twilio-chat/lib/message';

import TextareaAutosize from 'react-textarea-autosize';

// TODO: Arya Logo
// import logo from '../../assets/images/aryaLogo.png';
// TODO: SCC Logo
import logo from '../../assets/images/sccLogo.png';
import {participantService} from "../../services/participant.service";
import {useAudioInputDevices} from './DeviceSelector/deviceHooks/deviceHooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      // TODO: Arya Background
      // backgroundColor: '#ffffff!important' as any,
      // TODO: SCC Background
      backgroundColor: '#eeeeee!important' as any,
    },
    container: {
      // TODO: Arya Background
      // backgroundColor: '#ffffff!important' as any,
      // TODO: SCC Background
      backgroundColor: '#eeeeee!important' as any,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        position: 'relative',
      },
      // TODO: Arya Background
      // backgroundColor: 'rgb(33,147,134)!important' as any,
      // TODO: SCC Background
      backgroundColor: '#16405B!important' as any,
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      margin: 0,
      marginBottom: 10,
      width: 200,
      color: 'white',
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    aryaLogo: {
      padding: 0,
    },
    aryaLogoImage: {
      height: '40px',
      maxWidth: '156px',
      boxSizing: 'border-box',
      objectFit: 'contain',
      objectPosition: 'left',
      marginTop: '5px',
      marginLeft: '7px',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
      color: 'white',
    },
    modalContainer: {
      height: 'auto',
      width: 'auto',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '30px',
      fontSize: 15,
    },
    joinButton: {
      marginTop: 20,
      width: '200px',
      background: 'rgb(33,147,134)',
      color: 'white',
      '&:hover': {},
    },
    modalText: {
      fontSize: '17px',
      color: 'rgb(35,35,35)',
      width: '100%',
      textAlign: 'left',
      fontWeight: 600,
    },
    modalOpen: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    snackbar: {
      [theme.breakpoints.up('xs')]: {
        top: 90,
      },
    },
    snackbarContent: {
      backgroundColor: 'white',
    },
    snackbarMessage: {
      display: 'flex',
      alignItems: 'center',
      color: 'black',
    },
    snackbarIcon: {
      marginRight: '0.8em',
      color: 'black',
    },
    navButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '25px',
      width: '30px',
      marginLeft: '5px',
      marginRight: '10px',
    },
    navChatButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '25px',
      width: '30px',
      marginLeft: '10px',
      marginRight: '10px',
    },
    navNotesButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '25px',
      width: '30px',
      marginLeft: '5px',
      marginRight: '10px',
    },
    notesDrawer: {
      padding: '10px',
      position: 'relative',
      height: '100%',
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
    },
    chatDrawer: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '300px',
    },
    closeNotesDrawer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
    closeChatDrawer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      flex: '0 0',
    },
    chatTextField: {
      width: 'calc(100% - 20px)',
      display: 'flex',
      alignItems: 'flex-end',
      flex: '0 0',
      maxHeight: '50%',
      margin: '10px',
      position: 'relative',
    },
    chatField: {
      overflow: 'scroll',
      flex: '1 1 auto',
      height: '825px',
      // overflow: 'scroll',
    },
    chatUser: {
      padding: '3px 5px',
      color: 'black',
      fontWeight: 'bold',
    },
    chatUserMain: {
      padding: '3px 5px',
      color: 'black',
      fontWeight: 'bold',
      borderRadius: '3px',
      marginRight: '3px',
    },
    chatMessage: {
      padding: '5px 3px',
      color: 'black',
    },
    chatCell: {
      paddingBottom: '5px',
      paddingTop: '5px',
      marginLeft: '10px',
      marginRight: '10px',
    },
    notesTextField: {
      height: '300px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: '1.75',
      fontSize: '14px',
      resize: 'none',
      overflowY: 'auto',
    },
    input: {
      maxHeight: '100%',
      width: '100%',
    },
    textFieldSubmitButton: {
      borderRadius: '3px',
      color: 'white',
      border: 'none',
    },
    chatDrawerTitleCell: {
      height: '40px',
      width: 'calc(100% - 20px)',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #ccc',
      color: 'black',
      justifyContent: 'space-between',
      margin: '10px',
    },
    chatDrawerTitle: {
      fontSize: '17px',
      color: 'black',
    },
    noteDrawerTitleCell: {
      height: '40px',
      width: 'calc(100% - 20px)',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #ccc',
      color: 'black',
      justifyContent: 'space-between',
      margin: '10px',
    },
    noteDrawerTitle: {
      fontSize: '17px',
      color: 'black',
    },
    chatTextInput: {
      overflowY: 'scroll',
      width: '100%',
      padding: '3px',
      marginRight: '10px',
      height: 'calc(100% - 6px)',
      resize: 'none',
      minHeight: '36px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: '1.75',
      fontSize: '16px',
    },
    chatDrawerTitleSpacer: {
      width: '30px',
    },
    noteDrawerTitleSpacer: {
      width: '30px',
    },
    chatEmptyMessage: {
      height: '1px',
    },
    chatScrollMessage: {
      fontSize: '15px',
      padding: '5px',
      borderRadius: '5px',
      position: 'absolute',
      top: '-40px',
      backgroundColor: 'rgba(0,0,0,0.5)',
      left: 'calc(50% - 95px)',
      color: 'white',
      fontWeight: 'bold',
    },
    chatCellList: {
      display: 'block',
    },
    chatDrawerButtonCell: {
      position: 'relative',
    },
    unreadChatCount: {
      position: 'absolute',
      borderRadius: '50%',
      top: '-4px',
      right: '0px',
      border: '1px solid white',
      fontSize: '11px',
      height: '17px',
      width: '17px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'red',
      color: 'white',
    },
  })
);

let isSavingNotes: Boolean = false;
let shouldShowSnackbar: Boolean = true;
let notesChangedWhileSaving: Boolean = false;

let channelName:string = 'general';
let participantId:string = '';

let lastMessageSeenOn: Date = new Date();

export default function MenuBar() {
  const classes = useStyles();

  // TODO: Renable if running on Node Server. Also change in the Participant Service.
  const [testingLocally, setTestingLocally] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');
  const [gotRoomFromURL, setGotRoomFromURL] = React.useState(false);

  const [primaryColour, setPrimaryColour] = React.useState('');
  const [backgroundColour, setBackgroundColour] = React.useState('');
  const [fontColourLight, setFontColourLight] = React.useState('');
  const [fontColourDark, setFontColourDark] = React.useState('');

  const { URLRoomName } = useParams();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [signedParticipantId, setSignedParticipantId] = useState<string>('');
  const [roomLocked, setRoomLocked] = React.useState(false);
  const [canLockRoom, setCanLockRoom] = React.useState(false);

  const [currentNotes, setCurrentNotes] = React.useState('');
  const [notesDrawerOpen, setNotesDrawerOpen] = React.useState(false);

  const [chatChannel, setChatChannel] = React.useState<null | Channel>(null);
  const [chatDrawerOpen, setChatDrawerOpen] = React.useState(false);
  const [messagesArray, setMessagesArray] = React.useState<{ createdAt: Date; message: string; name: string }[]>([]);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);
  const [currentMessage, setCurrentMessage] = React.useState('');
  const [chatAutoScroll, setChatAutoScroll] = React.useState(true);

  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackbarMessage] = React.useState('');

  const messagesTextAreaDiv = React.useRef<HTMLTextAreaElement>(null);
  const messagesScrollViewDiv = React.useRef<HTMLDivElement>(null);
  const bottomOfMessagesScrollViewDiv = React.useRef<HTMLDivElement>(null);
  
  const { localTracks } = useVideoContext();
  const audioInputDevices = useAudioInputDevices();
  const [audioTrackCount, setAudioTrackCount] = useState(0);
  const localAudioTrack = localTracks.find(track => track.kind === 'audio');
  
  if(audioInputDevices && audioTrackCount !== audioInputDevices.length){
    setAudioTrackCount(audioInputDevices.length);
    
    if(localAudioTrack && audioInputDevices.length > 0){
      localAudioTrack.restart({groupId:{exact:audioInputDevices[0].groupId}});
    }
  }
  
  useEffect(() => {
    const parsedUrl: URL = new URL(window.location.href);
    let splitPathName: string[] = parsedUrl.pathname.split('/');

    if(URLRoomName){
      setRoomName(URLRoomName);
    }

    // Arya
    // setPrimaryColour('rgb(33,147,134)');
    //setBackgroundColour('#ffffff');
    //setFontColourDark('#black');
    //setFontColourLight('#eeeeee');
    //setLogo('../../assets/images/aryaLogo.png');

    // SCC
    setPrimaryColour('#16405b');
    setBackgroundColour('#eeeeee');
    setFontColourDark('#4a4a4a');
    setFontColourLight('#eeeeee');
    // setLogo("../../assets/images/aryaLogo.png");

    if(splitPathName.length > 2){
      let room = splitPathName[splitPathName.length - 2];
      let roomID = splitPathName[splitPathName.length - 1];

      if(room === 'room' && roomID != null && roomID.length > 0){
        setRoomName(roomID);
        setGotRoomFromURL(true);
      }
    }
    let signedIdFromUrl = parsedUrl.searchParams.get('participant_id');

    if(signedIdFromUrl !== null){
      setSignedParticipantId(signedIdFromUrl as string);
    }
  }, [URLRoomName]);

  useEffect(() => {
    if(chatDrawerOpen){
      shouldShowSnackbar = false;
      setShowSnackBar(false);
      setTimeout(() => {
        if(messagesTextAreaDiv !== null && messagesTextAreaDiv.current !== null){
          messagesTextAreaDiv.current.focus();
        }
      }, 10);
    }
    else{
      shouldShowSnackbar = true;
    }
  }, [chatDrawerOpen]);

  const createChatClient = (token: string) => {
    return new Promise<Client>((resolve, reject) => {
      Client.create(token).then(
        (client: Client) => {
          resolve(client);
          console.log('Chat Channel Created!');
        },
        error => {
          console.log('Chat Channel Failed to Create!');
          console.log(error.toString());
        }
      );
    });
  };

  const joinChatChannel = (chatClient: Client) => {
    if(testingLocally){
      return joinLocalChannel(chatClient);
    }
    else{
      return joinServerChatChannel(chatClient);
    }
  };

  const joinServerChatChannel = (chatClient: Client) => {
    return new Promise<Channel>((resolve, reject) => {
      chatClient
        .getSubscribedChannels()
        .then(() => {
          chatClient
            .getChannelBySid(channelName)
            .then((channel:Channel) => {
              setChatChannel(channel);

              if(channel.status !== 'joined'){
                channel
                  .join()
                  .then(() => {
                    handleNewMessage(new Date(), `Joined ${channelName} channel as ${name}.`, name, false);
                    window.addEventListener('beforeunload', () => channel.leave());
                    resolve(channel);
                  })
                  .catch(error => {
                    reject(Error(`Could not join ${channelName} channel.`));
                  });
              }
              else{
                handleNewMessage(new Date(), `Joined ${channelName} channel as ${name}.`, name, false);
                window.addEventListener('beforeunload', () => channel.leave());
                resolve(channel);
              }
            })
            .catch(reason => {
              console.log(reason);

              if(testingLocally){
                createGeneralChannel(chatClient);
              }
            })
        })
        .catch(() => reject(Error('Could not get channel list.')));
    });
  };

  const joinLocalChannel = (chatClient: Client) => {
    return new Promise<Channel>((resolve, reject) => {
      chatClient
        .getSubscribedChannels()
        .then(() => {
          chatClient
            .getChannelByUniqueName(channelName)
            .then((channel:Channel) => {
              setChatChannel(channel);

              if(channel.status !== 'joined'){
                channel
                  .join()
                  .then(() => {
                    handleNewMessage(new Date(), `Joined ${channelName} channel as ${name}.`, name, false);
                    window.addEventListener('beforeunload', () => channel.leave());
                    resolve(channel);
                  })
                  .catch(error => {
                    reject(Error(`Could not join ${channelName} channel.`));
                  });
              }
              else{
                handleNewMessage(new Date(), `Joined ${channelName} channel as ${name}.`, name, false);
                window.addEventListener('beforeunload', () => channel.leave());
                resolve(channel);
              }
            })
            .catch(reason => {
              console.log(reason);

              if(testingLocally){
                createGeneralChannel(chatClient);
              }
            })
        })
        .catch(() => reject(Error('Could not get channel list.')));
    });
  };

  const createGeneralChannel = (aChatClient: Client) => {
    return new Promise((resolve, reject) => {
      handleNewMessage(new Date(), `Creating ${channelName} channel...`, name, false);
      aChatClient
        .createChannel({ uniqueName: channelName, friendlyName: 'General Chat' })
        .then(() => joinChatChannel(aChatClient))
        .catch(() => reject(Error(`Could not create ${channelName} channel.`)));
    });
  };

  const configureChannelEvents = (channel: Channel) => {
    channel.on('messageAdded', (message: Message) => {
      if(message.author !== name && message.author !== participantId){
        if(testingLocally){
          handleNewMessage(message.dateCreated, message.body, message.author, true);

          if(shouldShowSnackbar){
            setSnackbarMessage(message.author + ': ' + message.body);
            setShowSnackBar(true);
          }
        }
        else{
          participantService.getParticipantName(message.author).then(name => {
            handleNewMessage(message.dateCreated, message.body, name, true);

            if(shouldShowSnackbar){
              setSnackbarMessage(name + ': ' + message.body);
              setShowSnackBar(true);
            }
          })
            .catch(reason => {
                console.log('Participant Name failed: ' + reason);
              }
            );
        }
      }
    });

    channel.on('memberJoined', member => {
      handleNewMessage(new Date(), `${member.identity} has joined the channel.`, member.identity, false);
    });

    channel.on('memberLeft', member => {
      handleNewMessage(new Date(), `${member.identity} has left the channel.`, member.identity, false);
    });
  };

  const handleNewMessage = (createdAt: Date, message: string, participantName: string, showInChat?: boolean) => {
    if(showInChat){
      let array = messagesArray;
      let newMessage = { createdAt: createdAt, message: message, name: participantName };
      array.push(newMessage);
      setMessagesArray(array);
      scrollToBottom();

      if(chatDrawerOpen){
        lastMessageSeenOn = new Date();
        setUnreadMessageCount(0);
      }
      else{
        updateUnreadMessages();
      }
    }
    else{
      console.log(participantName + ': ' + message);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if(reason !== 'clickaway'){
      setShowSnackBar(false);
    }
  };

  const updateUnreadMessages = () => {
    const unreadMessages = messagesArray.filter(message => message.createdAt > lastMessageSeenOn);
    setUnreadMessageCount(unreadMessages.length);
  };

  const onCloseChatDrawer = () => {
    setChatDrawerOpen(false);
    lastMessageSeenOn = new Date();
    updateUnreadMessages();
  };

  const onCloseNotesDrawer = () => {
    setNotesDrawerOpen(false);
  };

  const onToggleChatDrawer = () => {
    setNotesDrawerOpen(false);
    lastMessageSeenOn = new Date();
    updateUnreadMessages();
    setChatDrawerOpen(!chatDrawerOpen);
  };

  const onToggleNotesDrawer = () => {
    setChatDrawerOpen(false);
    setNotesDrawerOpen(!notesDrawerOpen);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const saveCurrentNotes = () => {
    if(testingLocally === false){
      if(isSavingNotes === false){
        isSavingNotes = true;
        notesChangedWhileSaving = false;

        setTimeout(() => {
          roomService.addNote(roomName, currentNotes, signedParticipantId, name).then(
            () => {
              console.log('Notes Changed!');
              isSavingNotes = false;

              if(notesChangedWhileSaving){
                saveCurrentNotes();
              }
            },
            error => {
              console.log('Failed to update Notes with error: ' + error.toString());
            }
          );
        }, 1000);
      }
    }
  };

  const handleCurrentNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentNotes(event.target.value);

    if(isSavingNotes){
      notesChangedWhileSaving = true;
    }
    saveCurrentNotes();
  };

  const handleCurrentMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentMessage(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if(testingLocally){
      // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
      if(!window.location.origin.includes('twil.io')){
        window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
      }
      getToken(name, roomName).then(token => {
        connect(token).then(() => {
          setSignedParticipantId(name);
          participantId = name;
          setCanLockRoom(true);
          createChatClient(token)
            .then((chatClient: Client) => joinChatChannel(chatClient))
            .then(channel => configureChannelEvents(channel))
            .catch((error: any) => {
              console.log(error?.toString());
            });
        });
      });
    }
    else{
      roomService.connect(name, roomName, signedParticipantId).then(
        parameters => {
          channelName = parameters.channelName;
          participantId = parameters.participantId;
          setRoomLocked(parameters.locked);
          setCanLockRoom(parameters.canLockRoom);
          setSignedParticipantId(parameters.signedParticipantId);
          connect(parameters.token).then(() => {
            createChatClient(parameters.token)
              .then((chatClient: Client) => joinChatChannel(chatClient))
              .then(channel => configureChannelEvents(channel))
              .catch((error: any) => {
                console.log(error?.toString());
              });
          });
        },
        error => {
          setErrorMessage(error.toString());
        }
      );
    }
  };

  const handleLockRoom = () => {
    if(testingLocally){
      setRoomLocked(!roomLocked);
    }
    else{
      roomService.lockRoom(roomName, !roomLocked, signedParticipantId, name).then(
        (is_locked: boolean) => {
          console.log('Room Lock Changed: ' + is_locked);
          setRoomLocked(is_locked);
        },
        error => {
          console.log('Room Failed to update Lock with error: ' + error.toString());
        }
      );
    }
  };

  const handleSendMessage = () => {
    if(currentMessage.length > 0){
      let array = messagesArray;
      let newMessage = { createdAt: new Date(), message: currentMessage, name: name };
      array.push(newMessage);
      setMessagesArray(array);
      scrollToBottom();
      lastMessageSeenOn = newMessage.createdAt;
      setCurrentMessage('');

      if(chatChannel != null){
        chatChannel.sendMessage(currentMessage).then(() => {
          if(testingLocally === false){
            roomService.addMessage(roomName, newMessage.message, signedParticipantId, name).then(
              () => {
                console.log('Message Sent!');
              },
              error => {
                console.log('Message Failed to Send with error: ' + error.toString());
              }
            );
          }
        });
      }
      setTimeout(() => {
        setCurrentMessage('');
      }, 15);
    }
  };

  const handleMessagesKeyPress = (event: any) => {
    if(event.key === 'Enter'){
      handleSendMessage();
      event.preventDefault();
    }
  };

  const handleMessagesScroll = () => {
    setTimeout(() => {
      if(messagesScrollViewDiv !== null && messagesScrollViewDiv.current !== null){
        if(messagesScrollViewDiv!.current.scrollTop + messagesScrollViewDiv!.current.offsetHeight < messagesScrollViewDiv!.current.scrollHeight - 3){
          setChatAutoScroll(false);
        }
        else{
          setChatAutoScroll(true);
        }
      }
    }, 100);
  };

  const scrollToBottom = () => {
    if(chatAutoScroll){
      setTimeout(() => {
        if(bottomOfMessagesScrollViewDiv !== null && bottomOfMessagesScrollViewDiv.current !== null){
          bottomOfMessagesScrollViewDiv!.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
      }, 10);
    }
  };

  return (
    <>
      <AppBar className={classes.container} position="static">
        <Toolbar className={classes.toolbar} style={{ backgroundColor: primaryColour }}>
          <div className={classes.aryaLogo} style={{ backgroundColor: primaryColour }}>
            <img src={logo} className={classes.aryaLogoImage} />
          </div>

          <div className={classes.rightButtonContainer}>
            {canLockRoom ? (
              roomLocked ?
                <LockIcon className={classes.navButton}
                          onClick={handleLockRoom}
                />
                :
                <LockOpenIcon className={classes.navButton}
                              onClick={handleLockRoom}
                />
              )
              :
              null
            }
            
            <FlipCameraButton/>
            
            {/*<LocalAudioLevelIndicator/>*/}
            
            {/*TODO: Move to bottom nav bar.*/}
            <ToggleFullscreenButton />
          </div>

          {canLockRoom ? <NoteAddIcon onClick={onToggleNotesDrawer} className={classes.navNotesButton} /> : null}

          <div className={classes.chatDrawerButtonCell}>
            <ChatIcon onClick={onToggleChatDrawer} className={classes.navChatButton} />
            {unreadMessageCount > 0 ? <div className={classes.unreadChatCount}>{unreadMessageCount}</div> : null}
          </div>
          
          <Menu className={classes.navButton}/>
        </Toolbar>

        <Modal open={roomState === 'disconnected'} className={classes.modalOpen}>
          <form
            className={classes.modalContainer}
            onSubmit={handleSubmit}
            style={{ color: fontColourDark, backgroundColor: backgroundColour }}
          >
            {errorMessage.length > 0 ? (
              <div className={classes.modalText} style={{ color: fontColourDark }}>
                {errorMessage}
              </div>
            ) : (
              <div className={classes.modalText} style={{ color: fontColourDark }}>
                Enter your name before entering the room.
              </div>
            )}
            {window.location.search.includes('customIdentity=true') || !user?.displayName ? (
              <TextField
                id="menu-name"
                label="Name"
                className={classes.textField}
                style={{ color: fontColourLight }}
                value={name}
                onChange={handleNameChange}
              />
            ) : (
              <Typography className={classes.displayName} variant="body1">
                {user.displayName}
              </Typography>
            )}

            {gotRoomFromURL === false ? (
              <TextField
                id="menu-room"
                label="Room"
                className={classes.textField}
                value={roomName}
                onChange={handleRoomNameChange}
              />
            ) : null}
            {isConnecting || isFetching ? (
              <CircularProgress
                className={classes.loadingSpinner}
                style={{ color: primaryColour, marginTop: '20px', height: '36px' }}
              />
            ) : (
              <Button
                className={classes.joinButton}
                style={{ color: fontColourLight, backgroundColor: primaryColour }}
                type="submit"
                variant="contained"
                disabled={isAcquiringLocalTracks || isConnecting || !name || !roomName || isFetching}
              >
                Join Room
              </Button>
            )}
          </form>
        </Modal>
      </AppBar>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackBar}
        onClose={handleCloseSnackbar}
        autoHideDuration={3500}
        className={classes.snackbar}
      >
        <SnackbarContent
          className={classes.snackbarContent}
          message={
            <span className={classes.snackbarMessage}>
              <ChatIcon className={classes.snackbarIcon} />
              {snackBarMessage}
            </span>
          }
        />
      </Snackbar>

      <Drawer anchor="right" open={notesDrawerOpen} onClose={onCloseNotesDrawer}>
        <div className={classes.notesDrawer}>
          <div onClick={onCloseNotesDrawer} className={classes.closeNotesDrawer}>
            <div className={classes.noteDrawerTitleCell}>
              <KeyboardTabIcon />
              <div className={classes.noteDrawerTitle}>Practioner Notes</div>
              <div className={classes.noteDrawerTitleSpacer}></div>
            </div>{' '}
          </div>
          <div>
            <textarea
              className={classes.notesTextField}
              id="notes"
              value={currentNotes}
              placeholder={'Type your TeleHealth call notes here.'}
              onChange={handleCurrentNotesChange}
            ></textarea>
          </div>
        </div>
      </Drawer>

      <Drawer anchor="right" open={chatDrawerOpen} onClose={onCloseChatDrawer}>
        <div className={classes.chatDrawer}>
          <div className={classes.closeChatDrawer} onClick={onCloseChatDrawer}>
            <div className={classes.chatDrawerTitleCell}>
              <KeyboardTabIcon />
              <div className={classes.chatDrawerTitle}>Chat</div>
              <div className={classes.chatDrawerTitleSpacer}></div>
            </div>
          </div>
          <div className={classes.chatField} ref={messagesScrollViewDiv} onScroll={handleMessagesScroll}>
            <div className={classes.chatCellList}>
              {messagesArray.map((message, i) => (
                <div className={classes.chatCell} key={i}>
                  {message.name == name ? (
                    <span
                      className={classes.chatUserMain}
                      style={{ backgroundColor: primaryColour, color: fontColourLight }}
                    >
                      {message.name}
                    </span>
                  ) : (
                    <span className={classes.chatUser}>{message.name}</span>
                  )}
                  <span>:</span>
                  <span className={classes.chatMessage}>{message.message}</span>
                </div>
              ))}
            </div>
            <div className={classes.chatEmptyMessage} ref={bottomOfMessagesScrollViewDiv}></div>
          </div>

          <div className={classes.chatTextField}>
            {chatAutoScroll ? null : <div className={classes.chatScrollMessage}>Chat paused due to scroll</div>}
            <TextareaAutosize
              id="chat"
              maxRows={5}
              minRows={1}
              className={classes.chatTextInput}
              value={currentMessage}
              onChange={handleCurrentMessageChange}
              onKeyPress={handleMessagesKeyPress}
              ref={messagesTextAreaDiv}
            />
            <Button
              className={classes.textFieldSubmitButton}
              style={{ backgroundColor: primaryColour }}
              onClick={handleSendMessage}
            >
              Send
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}
